:root {
  --blue-color: #1663b6;
  --orange-color : #f7941d;
  --dark-orange-color : #dd7d08;
  --light-gray-color : #e4e4e4;
  --dark-gray-color : #e6e6e6;
}


body {
  font-family: 'Archivo', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



