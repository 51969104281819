.product-header-holder .button {
    background: var(--orange-color);
    color: white;
    padding: 0.2rem 2rem;
    width: fit-content;
    margin-right: auto;
    border-radius: 0.9rem;
    margin-left: 2rem;
    font-weight: 300;
    transition: all 100ms linear 0ms;
    border: 1px solid var(--orange-color);
    min-width: fit-content;
}
.options-holder {
    display: flex;
    margin-top: 0.6rem;
    justify-content: space-between;
}
.wishlist,
.add-to-cart {
    height: 2rem;
    margin: 0rem 0.2rem;
}
.add-to-cart img {
    height: 100%;
}
.wishlist {
    opacity: 1;
}
.color-holder-red {
    background-color: #f85b5b;
    height: 18px;
    width: 18px;
    margin-right: 4px;
}
.color-holder-blue {
    background-color: #48a4fb;
    height: 18px;
    width: 18px;
    margin-right: 4px;
}
.color-holder-orange {
    background-color: #ffb939;
    height: 18px;
    width: 18px;
    margin-right: 4px;
}
.colors {
    margin-bottom: 4px;
    display: flex;
}
.wishlist:hover,
.add-to-cart:hover {
    opacity: 0.6;
    cursor: pointer;
}
.wishlist {
    position: absolute;
    top: 3.5rem;
    left: 14rem;
}
.square {
    width: 12px;
    height: 12px;
    margin-right: 0.425rem;
    background-color: rgb(1, 196, 1);
    border-radius: 0.2rem;
}
.product-header-holder .button:hover {
    background: transparent;
    color: var(--orange-color);
    border: 1px solid var(--orange-color);
    cursor: pointer;
}
.product-header-holder .headline {
    height: 1px;
    width: 100%;
    background: #e9e9e9;
}
.product-header-holder {
    display: flex;
    align-items: center;
    padding-left: 5rem;
}
.product-list-header h5 {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    font-size: 2rem;
    font-weight: 300;
}
.product-list-header .title-holder {
    display: flex;
    align-items: center;
}
.product-list-holder {
    margin: 1rem 0rem 0rem 0rem;
    padding: 4rem 5rem;
    background-color: #f9f9f9;
}
.product-list-header {
    padding: 0rem 2rem;
}
.product-col .product-holder {
    border: 1px solid #f7f7f7;
    padding: 0.1rem 0.4rem 1.4rem 0.4rem;
    transition: all 150ms linear 0ms;
    border-radius: 0.9rem;
    margin: 0.3rem;
    background: white;
}
.product-body-holder .product-col {
    padding: 0rem;
}
.product-col:last-child .product-holder:hover,
.product-col .product-holder:hover {
    border: 1px solid transparent;
    background: white;
    position: relative;
    z-index: 200;
    box-shadow: 2px 2px 62px -39px rgb(0 0 0 / 35%);

}
.product-col .product-holder:hover {
    padding: 0rem 0.3rem 1.3rem 0.3rem;
    transition: all 150ms linear 0ms;
}
.product-body-holder {
    padding: 4rem 0rem 2rem 0rem;
}
.product-holder .info-holder {
    padding: 0.6rem 1rem 0rem 1rem;
}
.product-holder .info-holder p {
    margin-bottom: 0.1rem;
    font-weight: 400;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    font-size: 0.9rem;
    -webkit-box-orient: vertical;
    min-height: 2.7rem;
}
p.code {
    min-height: unset!important;
}
.product-holder .info-holder h5 {
    font-size: 1rem;
    font-weight: 700;
}
.product-list-holder .buy-button {
    padding: 0.2rem 2rem;
    width: fit-content;
    margin-right: 0.8rem;
    border-radius: 0.9rem;
    display: flex;
    align-items: center;
    transition: all 100ms linear 0ms;
    min-width: fit-content;
    background: transparent;
    color: var(--orange-color);
    font-weight: 300;
    border: 1px solid var(--orange-color);
}
.product-list-holder .buy-button:hover {
    background: var(--orange-color);
    color: white;
}
.product-list-holder a {
    color: var(--orange-color);
    text-decoration: none;
}
.product-list-holder:hover a {
    color: var(--orange-color);
}
.product-list-holder .buy-button:hover a {
    color: white;
}
.product-list-holder .image-holder {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.badge {
    background: var(--blue-color);
    color: white;
    position: absolute;
    top: 1rem;
    border-radius: 0.9rem;
    right: 1rem;
    font-size: 12px;
    padding: 0.4rem 1rem;
    display: flex;
    align-items: center;
    align-self: center;
}
.badge p {
    margin-bottom: 0rem;
}
.lagerlink {
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
}
.lagerlink p.lager {
    font-size: 0.9rem;
    color: rgb(1, 196, 1);
    font-weight: 600;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    min-height: 0rem;
}
.product-holder {
    position: relative;
}
@media (max-width: 760px) {
    .headline {
        display: none;
    }
    .product-list-header .title-holder {
        justify-content: center;
    }
    .product-header-holder .button {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-top: 1.2rem;
    }
    .product-header-holder {
        padding-left: 0rem;
    }
    .product-list-holder {
        padding: 0rem 1rem;
    }
}
@media (max-width: 460px) {
    .product-list-holder .buy-button {
        padding: 0.2rem 1.4rem;
    }
}
@media (max-width: 410px) {
    .product-list-holder .buy-button {
        padding: 0.2rem 0.4rem;
    }
}
@media (max-width: 410px) {
    .product-list-holder .buy-button {
        padding: 0.2rem 0.8rem;
        text-align: center;
    }
}