
.mobile-nav-holder {
    display: none;
}
.mobile-search {
    display: none;
}
.header-links-holder {
    width: fit-content;
    float: right;
    margin-right: 1%;
    padding: 0.4rem 0rem 0.6rem 0rem;
}
.header-links-holder ul li {
    margin: 0rem 0rem 0rem 1rem;
    color: black;
    font-size: 14px;
    font-weight: 200;
}
.category {
    background: var(--orange-color);
    border-radius: 0.9rem;
    padding: 0.3rem 1rem!important;
}
.nav-list .dropdown-hover .category {
    color: white!important;
}

.icons-list li span {
    position: absolute;
    height: 23px;
    width: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    right: -10px;
    bottom: 1px;
    color: white;
    background: var(--blue-color);
}
.search {
    right: 1.4rem;
    position: absolute;
    top: 0.9rem;
    color: #8a8a8a;
}
.icons-list li {
    position: relative;
}
.top-header-links .header-links-holder ul {
    display: flex;
    list-style: none;
    margin: 0rem;
    padding: 0.1rem 0rem 0.4rem 0rem;
}
.header-info-line .address {
   text-align: start;
}
.header-info-line .phone {
    text-align: end;
 }
.header-info-line p {
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    color: #f9f9f9;
}
.top-header {
    font-size: 14px;
    border-bottom: 1px solid var(--light-gray-color);
    padding-bottom: 0.425rem;
}
.nav-holder {
    justify-content: left;
    display: flex;
}
.custom {
    display: flex;
}
button.dropdown-button {
    border-radius: 0.9rem;
    display: flex;
    align-items: center;
    padding: 0.465rem 1.6rem;
    background: var(--orange-color);
    border: 1px solid var(--orange-color);
    font-size: 14px;
}
button.dropdown-button:after {
    margin-left: 4.725em;
}
.image-placer {
    margin-right: 0.4rem;
    display: flex;
    align-items: center;
}
.header-links-holder span {
    color: var(--orange-color);
}
.icons-holder .icons-list a:hover img {
    opacity: 0.3;
}
.icons-holder .icons-list a img {
    opacity: 0.8;
}
.header-elements-holder {
    display: flex;
    width: 100%;
}
.search-input {
    border-radius: 1.5rem;
    border: 1px solid transparent;
    background: #f9f9f9;
    padding: 0.665rem 1.6rem;
    margin-left: 1rem;
    width: 100%;
    font-weight: 300;
}
.search-holder {
    width: 100%;
    position: relative;
    display: flex;
}

.search-input:focus,
.search-input:active,
.search-input:visited,
.search-input:focus-visible
 {
    box-shadow: none!important;
    background: var(--dark-gray-color)!important;
    border: 1px solid var(--dark-gray-color)!important;
    outline: none;
}
.icons-list {
    display: flex;
    justify-content: end;
    list-style: none;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    width: fit-content;
    margin-left: 1rem;
    padding-left: 0rem;
}
.icons-list li {
    margin: 0rem 0.3rem;
    padding: 0.2rem 0.25rem 0rem 0.4rem;
    display: flex;
    width: 38%;
}
.icons-list li:last-child {
    border-right: none;
}
.icons-holder {
    display: flex;
    align-items: center;
}
.nav {
    font-size: 15px;
    margin-top: 0.2rem;
    position: inherit;
}
.nav a {
    margin: 0rem 1.2rem;
    position: inherit;
}
.holder {justify-content: center;}
.col-holder {
    display: flex;
    align-items: center;
    justify-content: start;
}
.nav ul.nav-list {
    list-style: none;
    display: flex;
    padding: 0rem;
    margin: 0rem;
    align-items: center;
}
.word {
    opacity: 0;
    position: absolute;
    left: 0rem;
    z-index: 20000;
    transition: all .3s ease-in-out;
    pointer-events: none;
}
.animation {
    opacity: 1;
    
    padding-top: 5rem;
    padding-bottom: 5rem;
    left: 0rem;
    background: white;
    z-index: 20000;
    position: absolute;
    top: 6.5rem;
    border-top: 1px solid #f2f2f2;
    box-shadow: 0px 22px 31px -31px rgb(0 0 0 / 15%);
    transition: all .3s ease-in-out;
}
a.dropdown-holder {
    text-align: center;
    padding-bottom: 0.6rem;
    margin-bottom: 0rem;
}
.navbar-menu {
    position: inherit;
}
.nav-submenu {
    display: flex;
    list-style: none;
    margin: 0rem;
    justify-content: space-around;
    padding: 0rem 3rem;
}
.nav-submenu h5 {
    margin-bottom: 1rem;
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 0.2rem;
}
ul.product-list {
    list-style: none;
    padding-left: 0rem;
}
ul.product-list li {
    font-weight: 100;
    padding: 0.2rem 0rem;
}
ul.product-list li a {
    padding-left: 0rem;
    margin-left: 0rem;
    color: black;
    text-decoration: none;
}
ul.product-list li:hover a {
    text-decoration: underline;
    color: black;
}
.submenu-item-holder .image-holder img {
    margin-bottom: 1rem;
}
.submenu-item-holder .image-holder {
    display: flex;
    justify-content: center;
}
.nav-submenu-item {
    display: flex;
    justify-content: center;
}
ul.nav-list li .navlink {
    color: rgba(0, 0, 0, 0.85)!important;
    font-weight: 600;
    text-transform: uppercase;
    text-decoration: none;
}
ul.nav-list li .navlink:hover {
    text-decoration: underline;
    cursor: pointer;
}
.logo-holder {
    display: flex;
    justify-content: start;
}
.navbar {
    position: inherit;
    padding: 0rem 5rem;
}
.navbar-menu {
    position: inherit;
}

/* responsive */

@media (max-width: 1700px) {
    .icons-list {
        margin-left: 0rem;
    }
    .navbar {
        padding: 0 3rem;
    }
    .nav-submenu {
        padding: 0rem 0.4rem;
    }
    .nav a {
        margin: 0rem 0.6rem;
    }
    .icons-list li {
        padding: 0rem;
    }
    .search-input {
        margin: 0rem;
    }
}
@media (max-width: 1100px) {
    .navbar {
        padding: 0 0.2rem;
    }
    .nav a {
        margin: 0rem 0.4rem;
        font-size: 13px;
    }
    .icons-list li span {
        height: 18px;
        width: 18px;
        font-size: 12px;
        right: -14px;
    }
}
@media (max-width: 992px) {
    .nav-holder,
    .top-header-links,
    .search-holder
     {
        display: none;
    }
    .icons-list li {
        padding: 0rem 0.2rem;
    }
    .icons-list li span {
        height: 22px;
        width: 21px;
        font-size: 15px;
        padding-top: 0px;
        padding-left: 1px;
        right: -9px;
    }
    .top-header {
        padding: 0.4rem 2.4rem 0.4rem 0.2rem;
    }
    .mobile-nav-holder {
        display: flex;
        align-items: center;
    }
    .mobile-navbar {
        position: inherit!important;
    }
    .mobile-nav-holder .mobile-nav .mobile-navbar {
        background-color: transparent!important;
    }
    .mobile-nav-holder .mobile-nav .mobile-navbar .toggle-button {
        border-color: transparent;
        padding: 0rem;
    }
    .mobile-nav-holder .mobile-nav .mobile-navbar .toggle-button:active,
    .mobile-nav-holder .mobile-nav .mobile-navbar .toggle-button:focus,
    .mobile-nav-holder .mobile-nav .mobile-navbar .toggle-button:focus-visible,
    .mobile-nav-holder .mobile-nav .mobile-navbar .toggle-button:focus-within {
        box-shadow: none;
        outline: none;
    }
    .navcollapse {
        position: absolute;
        top: 4rem;
        background: white;
        z-index: 20000;
        padding: 2rem;
        width: 100vw;
        left: 0rem;
    }
    .mobile-search {
        display: flex;
        align-items: center;
    }
    .mobile-search .search-holder-mobile {
        width: 96%;
        margin-left: -12px;
    }
    .mobile-navbar a {
        text-align: center;
    }
    .submenu-item-holder {
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .submenu-item-holder h5 {
        font-size: 15px;
        padding-bottom: 0rem;
        margin-bottom: 0rem;
    }
    .submenu-item-holder .image-holder img {
        width: 42%;
        margin: 0.6rem 0rem 0.6rem 0rem;
    }
    .nav-submenu-item:first-child .submenu-item-holder .image-holder img {
        margin: 0rem 0rem 0.6rem 0rem;
    }
    .nav-submenu-item:first-child .submenu-item-holder h5 {
        margin-bottom: 0.4rem;
    }
    .nav-submenu-item {
        border-bottom: 1px solid #d7d7d7;
    }
    .mobile-nav-link,
    .mobile-nav-dropdown {
        border-bottom: 1px solid #e8e8e8;
    }
    .nav-submenu-item:last-child {
        border: none;
    }
    .submenu-item-holder .image-holder img {
        margin: unset;
        width: 100%;
    }
    .submenu-item-holder .image-holder {
        margin: 0.1em 0rem;
        padding-right: 8px;
    }
}
@media (max-width: 702px) {
    .search-holder-mobile {
        width: 100%;
    }
   .icons-list li {
       width: 28%!important;
   }
   .top-header {
    padding: 0.4rem 0.8rem 0.4rem 0.2rem;
   }
   .search-holder-mobile {
       width: 98%!important;
   }
}
@media (max-width: 590px) {
    .icons-list li {
        width: 40%!important;
    }
    .icons-list li span {
        height: 20px;
        width: 19px;
        font-size: 12px;
        padding-top: 0px;
        right: -4px;
    }
    .search-holder-mobile {
        width: 60%!important;
    }
    .mobile-none {
        display: none;
    }
}
@media (max-width: 570px) {
    .search-holder-mobile {
        width: 65%!important;
    }
    .icons-list li {
        width: 30%!important;
    }
}
@media (max-width: 530px) {
    .top-header {
        padding: 0.4rem 2.4rem 0.4rem 0.2rem;
    }
    .icons-list li {
       padding: 0rem;
       padding-right: 12px;
       margin-left: -2px;
       margin-right: 2px;
       width: 40%!important;
    }
    .search-holder-mobile {
        width: 80%!important;
        margin-left: -35px!important;
    }
    .icons-list li span {
        right: 4px;
        bottom: -4px;
        height: 21px;
        width: 20px;
        padding-top: 1px;
        font-size: 13px;
    }
    .mobile-search {
        justify-content: start;
        padding: 0rem;
        margin-left: -8px;
    }
    .submenu-item-holder .image-holder img {
        margin: unset;
        width: 100%;
    }
    .submenu-item-holder .image-holder {
        margin: 0.1em 0rem;
        padding-right: 8px;
    }
}
