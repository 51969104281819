/*hover effect*/
.box{
    text-align: center;
    overflow: hidden;
    position: relative;
    border-radius: 1.5rem;
}
.box:hover{ box-shadow: 0 0 20px -7px rgba(0,0,0,0.35); }
.box:before{
    content: '';
    background: linear-gradient( #ffffff00, #000000);
    height: 0;
    width: 100%;
    opacity: 0.8;
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: 1;
    transition: all 0.5s ease 0s;
    border-radius: 1.5rem;
}
.box:hover:before{ height: 100%; }
.box img{
    width: 100%;
    height: auto;
    transform: scale(1.3);
    transition: all 0.5s ease 0s;
}
.box:hover img{ transform: scale(1); }
.box .box-content{
    color: #000;
    width: 100%;
    padding: 7px 10px 5px;
    position: absolute;
    bottom: -100px;
    left: 0;
    z-index: 2;
    transition: transform 0.5s ease 0.2s,bottom 0.5s ease 0.1s;
}
.box:hover .box-content{
    bottom: 2.2rem;
    transform: rotate(0);
}
.box .title{
    font-size: 22px;
    font-weight: 600;
    text-transform: capitalize;
    margin: 0 0 1px;
}
.box .post{
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;
    letter-spacing: 1px;
    display: block;
}




@media only screen and (max-width:990px){
    .box{ margin: 0 0 30px; }
}
.view-more {
    background: transparent;
    border-radius: 0.9rem;
    border: 1px solid var(--orange-color);
    padding: 0.2rem 2rem;
    color: var(--orange-color);
    font-weight: 300;
}
.view-more:hover {
    background: var(--orange-color);
    color: white;
}

.categories {
    margin-top: 7rem;
    padding: 0rem 5rem 3rem 5rem;
}
.categories-header-holder .button {
    background: var(--orange-color);
    color: white;
    padding: 0.2rem 2rem;
    width: fit-content;
    margin-right: auto;
    border-radius: 0.9rem;
    margin-left: 2rem;
    transition: all 100ms linear 0ms;
    border: 1px solid var(--orange-color);
    min-width: fit-content;
    font-weight: 300;
}
.categories-header-holder .button:hover {
    background: transparent;
    color: var(--orange-color);
    border: 1px solid var(--orange-color);
    cursor: pointer;
}
.categories-header-holder .headline {
    height: 1px;
    width: 100%;
    background: #e9e9e9;
}
.categories-header-holder {
    display: flex;
    align-items: center;
    padding-left: 5rem;
}
.categories-header h5 {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    font-size: 2rem;
    font-weight: 300;
}
.categories-header .title-holder {
    display: flex;
    align-items: center;
}
.categories-list-holder {
    margin: 2rem 0rem;
}
.categories-header {
    padding: 0rem 2rem;
}
.categories-col .categories-holder {
    margin-left: auto;
    margin-right: auto;
    transition: all 150ms linear 0ms;
    padding: 0rem 0.4rem;
    border-radius: 2.4rem;
    overflow: hidden;
}
.categories-body-holder .categories-col {
    padding: 0rem;
}
.categories-body-holder {
    padding: 4rem 0rem 0rem 0rem;
}
.categories-holder .info-holder {
    padding: 0.6rem 1rem 0rem 1rem;
}
.categories-holder .info-holder p {
    margin-bottom: 0.1rem;
    font-weight: 100;
    color: #868686;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    font-size: 0.9rem;
    -webkit-box-orient: vertical;
}
.categories-holder .info-holder h5 {
    font-size: 1rem;
    font-weight: 500;
}
.categories-holder .buy-button {
    padding: 0.2rem 2rem;
    width: fit-content;
    margin-right: auto;
    margin-top: 0.8rem;
    border-radius: 0.9rem;
    transition: all 100ms linear 0ms;
    min-width: fit-content;
    background: transparent;
    color: var(--orange-color);
    font-weight: 300;
    border: 1px solid var(--orange-color);
}
.categories-holder .buy-button:hover {
    background: var(--orange-color);
    color: white;
}
.categories-holder .image-holder img {
    border-radius: 1.5rem;
}
.categories-col a {
    text-align: center;
    color: black;
    text-decoration: none;
}
.categories-col:hover a {
    color: black;
}
.categories-col:hover {
    cursor: pointer;
}
.categories-col .text-holder {
    margin: 0.8rem 0rem;
}

@media (max-width: 760px) {
    .categories-col {
        margin-bottom: 1rem;
    }
    .headline {
        display: none;
    }
    .categories-header .title-holder {
        justify-content: center;
    }
    .categories-header .button-holder {
        display: flex;
        justify-content: center;
    }
    .categories-header-holder {
        padding-left: 0rem;
        padding-top: 1.2rem;
    }
    .categories-header-holder .button {
        margin-left: 0rem;
        font-weight: 300;
    }
    .categories {
        padding: 0rem 1rem;
    }
    .categories-body-holder {
        padding: 1rem 0rem 2rem 0rem;
    }

}