.footer-holder {
    padding: 3rem 0rem 1rem 0rem;
}
.footer-holder .image-holder {
    width: 60%;
}
.footer-holder .text-holder {
    padding-left: 0.625rem;
    margin: 1.8rem 0rem;
}
.footer-holder .text-holder p {
    margin-bottom: 0.1rem;
    font-size: 15px;
    color: #8D8E8E;
}
.footer-holder .text-holder p:nth-child(2) {
    margin-bottom: 1rem;
}
.footer-holder .text-holder a {
    color: var(--dark-gray-holder);
    margin-top: 0.4rem;
    font-size: 16px;
}
.footer-holder .text-holder ul.social {
    display: flex;
    list-style: none;
    padding-left: 0rem;
}
.footer-holder .text-holder ul.social li {
    margin: 0rem 0rem 0rem 0.8rem;
}
.footer-holder .text-holder ul.social li a {
    text-decoration: none;
}
.footer-holder .text-holder ul.social li a:hover {
    color: var(--orange-color);
}
.footer-holder .text-holder ul.social li:first-child {
    margin: 0rem;
}
.footer-company {
    border-right: 1px solid #dddddd;
    padding-left: 5rem;
}
.footer-top {
    border-bottom: 1px solid #dddddd;
    padding-bottom: 1rem;
}
.footer-info-holder {
    display: flex;
}
.info-holder {
    margin: 0rem 1rem;
    font-size: 15px;
}
.info-holder span {
    color: var(--orange-color);
    font-weight: 600;
}
.info-holder p.gray {
    margin-bottom: 0.1rem;
    font-size: 14px;
    color: #8D8E8E;
}
.info-holder p:last-child {
    margin-top: 1rem;
}
.links-holder {
    margin: 0rem 1rem;
}
.links-holder ul {
    list-style: none;
    padding-left: 0rem;
}
.links-holder ul li {
    margin-bottom: 0.3rem;
}
.links-holder ul li span {
    color: black;
    font-size: 16px;
}
.facebookicon .fb {
    margin-bottom: 2px;
}
.instaicon .insta {
    font-size: 17px;
}
.linkedinicon .linkedin {
    font-size: 18px;
}
.social {
    margin-top: 1rem;
}
.croonus {
    text-align: end;
}
.copyright {
    text-align: start;
}
.footer-bottom {
    padding: 0rem 5rem;
}
.footer-bottom p {
    font-size: 14px;
    padding-top: 1.6rem;
}
.footer-bottom span {
    color: var(--orange-color);
}
.links-holder a {
    text-decoration: none;
    font-size: 14px;
    color: #8D8E8E;
}
.links-holder a:hover {
    text-decoration: underline;
}
.underline {
    height: 3px;
    width: 30px;
    background: var(--orange-color);
    margin-bottom: 1.2rem;
}
p.title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
}

@media (max-width: 900px) {
    .footer-company {
        border-right: none;
        padding: 0rem;
        border-bottom: 1px solid #ddd;
    }
    .footer-info-holder {
        border-bottom: 1px solid #ddd;
    }
    .footer-holder .image-holder {
        margin-left: auto;
        margin-right: auto;
    }
    .footer-holder .text-holder {
        padding-left: 0rem;
        text-align: center;
    }
    .footer-holder .text-holder ul.social {
        justify-content: center;
    }
    .footer-info-holder {
        padding-top: 2rem;
    }
    .links-holder {
        padding-top: 1.6rem;
    }
}
@media (max-width: 752px) {
    .footer-info-holder {
        border-bottom: none;
    }
    .copyright,
    .croonus {
        padding: 0rem;
        text-align: center;
    }
    .copyright p { 
        padding-top: 1.4rem;
        padding-bottom: 0rem;
    }
    .croonus p {
        padding-top: 0rem;
    }
    .footer-holder {
        padding: 3rem 0rem 0.6rem 0rem;
    }
}
