.breadcrumbs-holder {
    padding: 2rem 5rem;
}
.breadcrumbs-holder ul {
    list-style: none;
    display: flex;
}
.breadcrumbs-holder ul li:after {
    content: ">";
    margin: 0rem 0.4rem;
    font-size: 13px;
    color: #8D8E8E;
}
.breadcrumbs-holder ul li:last-child:after {
    content: " ";
}
.breadcrumbs-holder ul li:last-child .breadcrumbs-link {
    color: black;
}
.breadcrumbs-holder ul li .breadcrumbs-link {
    color: #8D8E8E;
    font-size: 13px;
    text-decoration: none;
}