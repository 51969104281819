.banners-holder {
    margin: 2rem 5rem;
    padding: 0rem 1rem;
}
.big-banner .image-holder,
.big-banner .image-holder img {
    height: 100%;
}
.middle-banners .image-holder:first-child {
    margin-bottom: 1.3rem;
}
.small-banner .image-holder {
    height: 100%;
}
@media (max-width: 760px) {
    .big-banner {
        margin-bottom: 2rem;
    }
    .banners-holder {
        margin: 0rem;
    }
}