.variant1 .color {
    background-color: black;
    height: 40px;
    width: 40px;
    border-radius: .5rem;
    margin-bottom: 1rem;
}
.variant2 .color {
    background-color: gray;
    height: 40px;
    width: 40px;
    border-radius: .5rem;
    margin-bottom: 1rem;
}
.variant3 .color {
    background-color: var(--orange-color);
    height: 40px;
    width: 40px;
    border-radius: .5rem;
    margin-bottom: 1rem;
}
.variant1,
.variant2 {
    margin-right: 1rem;
}
.colors {
    margin: 4rem 6rem 1rem 7rem;
    display: flex;
}