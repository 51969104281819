.title  {  
        margin-bottom: 20px;  
        padding:20px;
}  
.img  {  
        height: 260px;
        width:100%;  
}  
.owl-dots {
    display: none;
}
.custom-nav {
    position: absolute;
    top: 20%;
    left: 0;
    right: 0;
}
    .owl-prev,
    .owl-next {
      position: absolute;
      height: 100px;
      color: inherit;
      background: none;
      border: none;
      z-index: 100;
    }

    .owl-prev {
      left: 0;
      top: 50%;
    }
    .owl-prev span, 
    .owl-next span {
        font-size: 3rem;
        font-weight: 100;
        padding: 0.2rem;
    }
    .owl-next {
      right: 0;
      top: 50%;
    }
    .owl-prev:hover {
        background-color: transparent;
      }
  
      .owl-next:hover {
        background-color: transparent;
      }
      .owl-theme .owl-nav [class*='owl-']:hover {
        background: #efefef;
    }
    .owl-holder {
        padding: 0rem 7rem;
    }
    
    @media (max-width: 767px) {
        .owl-holder {
            padding: 0rem;
        }
       
    }