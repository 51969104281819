.newsletter-holder .image-holder {
    width: 34%;
    margin-left: auto;
    margin-right: 1rem;
}
.newsletter-holder {
    padding: 3rem 0rem;
}
.newsletter-holder p {
    font-size: 14px;
    color: #8D8E8E;
}
.newsletter-holder h5 {
    margin-bottom: 1rem;
    font-size: 2rem;
    font-weight: 300;
}
.newsletter-holder span {
    color: var(--orange-color);
}
.newsletter-holder .button {
    background: var(--orange-color);
    color: white;
    padding: 0.45rem 2rem;
    width: fit-content;
    margin-right: auto;
    border-radius: 0.9rem;
    margin-bottom: 1rem;
    margin-left: 0.6rem;
    transition: all 100ms linear 0ms;
    border: 1px solid var(--orange-color);
    font-weight: 300;
}
.newsletter-holder .button:hover {
    background: transparent;
    color: var(--orange-color);
    border: 1px solid var(--orange-color);
    cursor: pointer;
}
.newsletter-holder .email-input {
    border-radius: 0.9rem;
    border: 1px solid transparent;
    background: #f9f9f9;
    padding: 0.465rem 1.6rem 0.465rem 1.6rem;
    min-width: 30rem;
}
.newsletter-holder .email-input:focus,
.newsletter-holder .email-input:active,
.newsletter-holder .email-input:visited,
.newsletter-holder .email-input:focus-visible
 {
    box-shadow: none!important;
    background: var(--dark-gray-color)!important;
    border: 1px solid var(--dark-gray-color)!important;
    outline: none;
}
.nl-elements-holder {
    display: flex;
}
.nl-error {
    font-size: 14px;
    color: red;
    top: 3.3rem;
    left: 0.2rem;
    position: absolute;
}
.email-holder {
    position: relative;
}
@media (max-width: 991px) {
    .newsletter-holder .image-holder {
        width: 100%;
    }
}
@media (max-width: 767px) {
    .newsletter-holder .email-input {
        padding: 0.465rem 7.6rem 0.465rem 1.6rem;
    }
    .newsletter-holder .image-holder {
        width: 30%;
        margin-right: auto;
    }
    .newsletter-holder h5 {
        text-align: center;
        margin-top: 1rem;
        font-size: 21px;
    }
}
@media (max-width: 450px) {
    .newsletter-holder h5 {
        font-size: 1.6rem;
    }
    .newsletter-holder .email-input {
        padding: 0.465rem 0.6rem;
        min-width: 14rem;
    }
}