.related-product-header-holder .button {
    background: var(--orange-color);
    color: white;
    padding: 0.4rem 2rem;
    width: fit-content;
    margin-right: auto;
    border-radius: 1.5rem;
    margin-left: 2rem;
    transition: all 100ms linear 0ms;
    border: 1px solid var(--orange-color);
    min-width: fit-content;
}
.related-product-holder {
    margin: 0rem 5rem;
}
.options-holder {
    display: flex;
    margin-top: 1rem;
    justify-content: space-between;
}
.wishlist,
.add-to-cart {
    width: 14%;
    margin: 0rem 0.2rem;
}
.wishlist {
    position: absolute;
    top: 1rem;
    left: 0.7rem;
}
.square {
    width: 12px;
    height: 12px;
    margin-right: 0.425rem;
    background-color: rgb(1, 196, 1);
    border-radius: 0.2rem;
}
.related-product-holder .button:hover {
    background: transparent;
    color: var(--orange-color);
    border: 1px solid var(--orange-color);
    cursor: pointer;
}
.related-product-header .headline {
    height: 1px;
    width: 100%;
    background: #e9e9e9;
}
.related-product-header-holder  {
    display: flex;
    align-items: center;
    padding-left: 5rem;
}
.related-product-header h5 {
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    font-size: 2rem;
    font-weight: 300;
    white-space: nowrap;
}
.related-product-header .title-holder {
    display: flex;
    align-items: center;
    padding: 0rem;
    margin:0rem;
}
.related-product-header-wrapper {
    padding: 0rem;
    margin: 0rem;
}
.related-product-header-holder {
    margin: 1rem 0rem 0rem 0rem;
    padding: 4rem 0rem;
}
.related-product-header {
    padding: 0rem 2rem;
}
.product-col .product-holder {
    border: 1px solid #f7f7f7;
    padding: 0rem 0.4rem 1.4rem 0.4rem;
    transition: all 150ms linear 0ms;
    border-radius: 1.5rem;
    margin: 0.3rem;
    background: white;
}
.related-product-body-holder .product-col {
    padding: 0rem;
}
.product-col:last-child .product-holder:hover,
.product-col .product-holder:hover {
    border: 1px solid transparent;
    background: white;
    transform: scale(101%);
    position: relative;
    z-index: 200;
    box-shadow: 2px 2px 62px -39px rgb(0 0 0 / 20%);
    transition: all 150ms linear 0ms;
}
.related-product-body-holder {
    padding: 0rem 0rem 2rem 0rem;
}
.product-holder .info-holder {
    padding: 0.6rem 1rem 0rem 1rem;
}
.product-holder .info-holder p {
    margin-bottom: 0.1rem;
    font-weight: 100;
    color: #333;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    line-clamp: 2;
    font-size: 0.9rem;
    -webkit-box-orient: vertical;
    min-height: 2.7rem;
}
p.code {
    min-height: unset!important;
}
.product-holder .info-holder h5 {
    font-size: 1rem;
    font-weight: 500;
}
.related-product-body-holder .buy-button {
    padding: 0.2rem 2rem;
    width: fit-content;
    margin-right: 0.8rem;
    border-radius: 1.5rem;
    display: flex;
    align-items: center;
    transition: all 100ms linear 0ms;
    min-width: fit-content;
    background: transparent;
    color: var(--orange-color);
    border: 1px solid var(--orange-color);
}
.related-product-body-holder .buy-button:hover {
    background: var(--orange-color);
    color: white;
}
.related-product-body-holder a {
    color: var(--orange-color);
    text-decoration: none;
}
.related-product-body-holder:hover a {
    color: var(--orange-color);
}
.related-product-body-holder .buy-button:hover a {
    color: white;
}
.related-product-body-holder .image-holder {
    width: 75%;
    margin-left: auto;
    margin-right: auto;
}
.badge {
    background: var(--blue-color);
    color: white;
    position: absolute;
    top: 1rem;
    border-radius: 50%;
    right: 1rem;
    font-size: 12px;
    padding: 0.6rem 0.4rem;
    display: flex;
    align-items: center;
    align-self: center;
}
.badge p {
    margin-bottom: 0rem;
}
.lagerlink {
    display: flex;
    align-items: center;
    margin-top: 0.2rem;
}
.lagerlink p.lager {
    font-size: 0.9rem;
    color: rgb(1, 196, 1);
    font-weight: 600;
    margin-bottom: 0rem;
    padding-bottom: 0rem;
    min-height: 0rem;
}
.product-holder {
    position: relative;
}
@media (max-width: 760px) {
    .headline {
        display: none;
    }
    .related-product-header .title-holder {
        justify-content: center;
    }
    .related-product-header .button {
        display: flex;
        justify-content: center;
        margin-left: auto;
        margin-top: 1.2rem;
    }
    .related-product-header-holder {
        padding-left: 0rem;
    }
    .related-product-header-holder {
        padding: 0rem 1rem;
    }
}
@media (max-width: 460px) {
    .related-product-header .buy-button {
        padding: 0.2rem 1.4rem;
    }
}
@media (max-width: 410px) {
    .related-product-header .buy-button {
        padding: 0.2rem 0.4rem;
    }
}
@media (max-width: 410px) {
    .related-product-header .buy-button {
        padding: 0.2rem 0.8rem;
        text-align: center;
    }
}