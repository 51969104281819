
.modal-nl {
    top: 26%!important;
}
.modal-nl .modal-header {
    background-color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}
.modal-nl .img-holder {
    width: 14%;
    margin-right: 2rem;
}
.modal-nl .text-holder h3 {
    margin-bottom: 0.2rem;
}
.modal-nl .text-holder p {
    margin-bottom: 0rem;
    font-size: 15px;
    color: #828282;
}