.benefits-holder {
    margin: 4rem 5rem;
    padding: 0rem 2rem;
}
.element-wrapper {
    display: flex;
    padding: 0.4rem 1rem;
}
.image-holder {
    max-width: 16%;
    padding-right: 1rem;
    padding-top: 0.2rem;
}
.text-holder h5 {
    font-weight: 500;
    font-size: 16px;
    letter-spacing: 1px;
    margin-bottom: 0.2rem;
}
.text-holder p {
    font-size: 14px;
    color: #333;
    letter-spacing: 0px;
    font-weight: 300;
    margin-top: 0.6rem;
}
.text-holder {
    width: 80%;
}
.line {
    height: 3px;
    width: 38px;
    background: var(--orange-color);
}
@media (max-width: 760px) {
    .benefits-holder {
        margin: 2.6rem 0rem;
    }
}