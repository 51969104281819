.specification-holder .lager .square {
        width: 14px;
        height: 14px;
        margin-right: 0.425rem;
        background-color: rgb(1, 196, 1);
        border-radius: 0.2rem;
}
.specification-holder .lager {
    display: flex;
    align-items: center;
    margin-bottom: 0.6rem;
}
.specification-holder .lager p {
    padding-bottom: 0rem;
    margin-bottom: 0rem;
    color: rgb(1, 196, 1);
    font-weight: 600;
}
.specification-holder {
    padding: 0rem 6rem;
}
.spec-title h1 {
    font-size: 2rem;
    margin-bottom: 0rem;
}
.spec-price button {
    background: var(--orange-color);
    border: 1px solid var(--orange-color);
    border-radius: 1.5rem;
    padding: 0.4rem 2rem;
    color: white;
    margin: 0.2rem 0rem 2rem 0rem;
}
.spec-price button:hover {
    background: white;
    color: var(--orange-color);
}
.spec-info p {
    font-weight: 600;
    margin-bottom: 0.2rem;
}
.spec-info {
    margin-top: 1rem;
    padding: 3rem 2rem 0rem 2rem;
    border-radius: 1.5rem;
    background: #f8f8fa;
}
.spec-info ul {
    list-style: none;
    padding-left: 0rem;
    margin: 1rem 0rem 2rem 0rem;
}
.line {
    height: 3px;
    width: 30px;
    background: var(--orange-color);
}
.spec-info ul li span {
    font-weight: 300;
    min-width: 30%;
}
.spec-info ul li {
    font-weight: 500;
    display: flex;
    margin-bottom: 0.4rem;
    max-width: 60%;
}
.table:first-child {
    margin-bottom: 2rem;
}
.table td {
    width: 50%;
}
.table tr:last-child {
    border-bottom: transparent;
}
.table tr td:last-child {
    font-weight: 300;
}