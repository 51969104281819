.button {
    background: var(--orange-color);
    color: white;
    padding: 0.6rem 2rem;
    width: fit-content;
    margin-right: auto;
    border-radius: 1.5rem;
    margin-bottom: 1rem;
    transition: all 100ms linear 0ms;
}
.mobile-display {
    display: none;
}
.desktop-display {
    width: 100%;
}
.button:hover {
    background: white;
    color: var(--orange-color);
    cursor: pointer;
}
.custom-caption {
    bottom: 3.25rem!important;
    left: 20%;
    text-align: left!important;
    width: 31%;
}
.custom-caption h1 {
    font-size: 8rem;
    color: #8a8a8a80;
    letter-spacing: 2px;
}

@media (max-width: 1599px) {
    .custom-caption {
        bottom: 3.25rem!important;
    }
    .custom-caption h1 {
        font-size: 6rem;
    }
}
@media (max-width: 1299px) {
    .custom-caption {
        bottom: 1.25rem!important;
    }
    .custom-caption h1 {
        font-size: 5rem;
    }
}
@media (max-width: 1159px) {
    .custom-caption {
        bottom: 2.25rem!important;
        width: 51%;
    }
    .custom-caption h1 {
        font-size: 4rem;
    }
}
@media (max-width: 1059px) {
    .custom-caption {
        bottom: .25rem!important;
        width: 51%;
    }
    .custom-caption h1 {
        font-size: 4rem;
    }
}
@media (max-width: 985px) {
    .custom-caption {
        bottom: .15rem!important;
        width: 81%;
    }
    .custom-caption h1 {
        font-size: 3rem;
    }
}
@media (max-width: 805px) {
    .custom-caption {
        bottom: .15rem!important;
        width: 81%;
    }
    .custom-caption h1 {
        font-size: 2rem;
    }
    .custom-caption h3 {
        font-size: 18px;
    }
    .custom-caption p {
        font-size: 14px;
    }
}
@media (max-width: 705px) {
   .mobile-display {
       display: block;
       width: 100%;
   }
   .desktop-display {
       display: none;
   }
   .custom-caption {
        bottom: 14.15rem!important;
        width: 70%;
        left: 10%!important;
    }
    .custom-caption h1 {
        font-size: 5rem;
    }
    .custom-caption h3 {
        font-size: 26px;
    }
    
}